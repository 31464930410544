$theme-colors: (
    "brand": #e5873b,
    "acme": #cd5241,
    "primary": #2aa7a7,
);

.auth-login-qr-box canvas {
    height: 100% !important;
    width: 100% !important;
}

body {
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.radius-10 {
    border-radius: 10px !important;
}

.border-info-1 {
    border-left: 5px solid #0dcaf0 !important;
}

#userManagementContainer .card {
    margin-bottom: 1.5rem;
}

#userManagementContainer .user-picture {
    height: 50px;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 50px !important;
}

.widgets-icons-2 {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 16px;
    border-radius: 10px;
}

#add-user-form label.required:after {
    content: " *";
    color: red;
}

.rounded-none {
    border-radius: 0 !important;
}

.rounded-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.rounded-end {
    border-bottom-right-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.request-action-btn-box {
    button {
        border-radius: 20px;
        font-weight: bold;
    }

    .approved {
        background-color: #c1f0ce;
        color: #08aa64 !important;

        i {
            background-color: #08aa64;
            padding: 6px;
            border-radius: 100%;
            color: #fff;
        }
    }

    .rejected {
        background-color: #f4b9c4;
        color: #d11439 !important;

        i {
            background-color: #d11439;
            padding: 6px 8px;
            border-radius: 100%;
            color: #fff;
        }
    }

    .view-detail {
        background-color: #a5d9ef;
        color: #0c75a1 !important;

        i {
            background-color: #0c75a1;
            padding: 6px 10px;
            border-radius: 100%;
            color: #fff;
        }
    }
}

.my-checkbox {
    transform: scale(1.5);
}

.requests-table {
    tbody {

        th,
        td {
            vertical-align: middle;
        }
    }

    thead {
        background-color: lightgray;
    }
}

.modal-footer-div {
    display: flex;
    flex:1,
}
.modal-footer-div div:nth-child(1) {
    flex:1,
}

.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #6074e4 !important;
    border-color: #6074e4 #6074e4 #6074e4 !important;
}

.refresh {
    cursor: pointer;
}

// AUTOCOMPLETE #########################

[role="option"]:hover {
    background: #ddddff;
    color: #000;
    border-color: #999;
    cursor: pointer;
}

.suggestions {
    position: relative;
}

[role="listbox"] {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    max-height: 200px;
    overflow-x: scroll;
}

[role="listbox"]::-webkit-scrollbar {
    display: none;
}

[role="option"] {
    padding: 0.25em;
    color: #999;
    border: 1px solid lightgray;
    border-top: 0;
}

// AUTOCOMPLETE #########################



// MEDIA QUERY #########################

// FOR MOBILE
@media screen and (max-width: 426px) {
    .my-xs-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
}

// FOR TABLET
@media (min-width: 427px) and (max-width: 768px) {
    #userManagementContainer .card-body h3 {
        font-size: 0.7rem !important;
    }

    #userManagementContainer .card-body h5 {
        font-size: 0.6rem !important;
    }

    .d-block-tablet {
        width: 100% !important;
        max-width: 100% !important;
        flex: 100% !important;
    }
}