.qrmodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
  
  .qroverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  #spinner {
    text-align: center;
  }
  
  .qr-image{
    width: 300px;
    height: 300px;
  }
  
  .centered-content {
    align-content: center;
    text-align: center;
  }

  .dropdown {
    min-width: 100%;
    /* background-color: white; */
}

.menu-dropdown {
  min-width: 100%;
  background-color: white;
}

.priv-statement{
  overflow: auto;
  height: 330px;
  background: rgba(233, 232, 232, 0.872);
  padding: 10px;
	border-radius: 7px;
  font-size: 15px;
  

}
.priv-statement-title{
  text-align: center !important;
}


.priv-statement::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	border-radius: 10px;
	background-color: rgba(233, 232, 232, 0.872);
}

.priv-statement::-webkit-scrollbar
{
	border-radius: 10px;
	width: 8px;
	background-color: rgba(233, 232, 232, 0.872);
}

.priv-statement::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #c9c7ca;
}
.priv-statement-modal .close{
  display: none;
}
